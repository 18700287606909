@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

.font-12 {
  font-size: 0.75rem;
}

.font-14 {
  font-size: 0.875rem;
}

.font-16 {
  font-size: 1rem;
}

.font-18 {
  font-size: 1.125rem;
}

.font-20 {
  font-size: 1.25rem;
}

.font-22 {
  font-size: 1.375rem;
}

.font-24 {
  font-size: 1.5rem;
}

.font-26 {
  font-size: 1.625rem;
}

.font-28 {
  font-size: 1.75rem;
}

.font-30 {
  font-size: 1.875rem;
}

.wg-1 {
  font-weight: 100;
}

.wg-2 {
  font-weight: 200;
}

.wg-3 {
  font-weight: 300;
}

.wg-4 {
  font-weight: 400;
}

.wg-5 {
  font-weight: 500;
}

.wg-6 {
  font-weight: 600;
}

.wg-7 {
  font-weight: 700;
}

.wg-8 {
  font-weight: 800;
}

.wg-9 {
  font-weight: 900;
}

.cl-nt-70 {
  color: #48484A;
}

.cl-nt-100 {
  color: #1C1C1E;
}

.input-primary {
  width: 100%;
  padding: 0.5rem 0.75rem;
  border-radius: 0.313rem;
  border: 1px solid #1C1C1E;
}

.input-primary-white:focus {
  outline: none;
  border-color: #CF3560;
}

.btn-primary {
  width: 100%;
  padding-block: 0.5rem;
  border: none;
  border-radius: 0.313rem;
  background-color: #CF3560;
  color: white;
  cursor: pointer;
}

.nca-textfield {
  border-radius: 0.4rem;
  border: 2px solid #1C1C1E;
  padding: 0.8rem 0.8rem;
}

.nca-textfield:focus {
  outline: none;
  border-color: #CF3560;
}

button {
  cursor: pointer;
}

.navigation {
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: space-between;
  padding: 0.938rem 1.125rem;
  z-index: 999;
  height: 100%;
  max-height: 6rem;
}

.navigation .nav-ham svg>path {
  stroke: #1C1C1E;
}

#logo {
  max-width: 4rem;
}

#logo img {
  width: 100%;
  height: auto;
}

.navigation-wrapper {
  width: 100%;
  max-width: 30rem;
  height: 100vh;
  z-index: 999;
  padding: 1.5rem;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  transform: translateX(-100%);
  transition: transform 0.2s ease 0s;
}

.navigation-wrapper .nav-top,
.navigation-wrapper .nav-bottom {
  width: 100%;
}

.navigation-wrapper .nav-bottom {
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 0.4rem;
}

.navigation-wrapper .nav-bottom button a {
  text-decoration: none;
  width: 100%;
  height: 100%;
  display: block;
  color: white;
}

.navigation-wrapper .nav-bottom a {
  font-size: 0.875rem;
  color: #1C1C1E;
}

.navigation-wrapper .mob-nav-logo {
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #E5E5EA;
  display: flex;
  justify-content: space-between;
}

.navigation-wrapper .mob-nav-logo span {
  color: #48484A;
}

.navigation-wrapper .routes {
  list-style-type: none;
  padding-top: 1rem;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
}

.navigation-wrapper .routes .submenu-enabled {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.navigation-wrapper .routes .main-links {
  padding: 0.5rem 0.2rem;
  width: 100%;
  height: auto !important;
  font-weight: 600;
  font-size: 0.875rem;
  position: relative;
}

.navigation-wrapper .routes .main-links .nav-links {
  color: #1C1C1E;
  text-decoration: none;
  cursor: pointer;
}

.navigation-wrapper .routes .main-links:nth-child(2) p {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.navigation-wrapper .routes .main-links .submenu {
  list-style-type: none;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  gap: 0.4rem;
  transform-origin: top center;
  padding-inline: 0;
  width: 100%;
}

.navigation-wrapper .routes .main-links .submenu li {
  border-radius: 0px !important;
  font-weight: 500;
  border-radius: 0.2rem;
  border-bottom: 1px solid #AEAEB2;
  width: 100%;
  cursor: pointer;
}

.navigation-wrapper .routes .main-links .submenu li a {
  width: 100%;
  display: block;
  padding: 0.4rem 0rem;
  text-decoration: none;
  color: #1C1C1E;
}

.navigation-wrapper .routes .main-links .submenu li:last-child {
  border-bottom: none;
}

.navigation-wrapper .routes .main-links .submenu li:hover {
  background-color: #F2F2F7;
}

.nca-footer {
  background-color: #1C1C1E;
  padding-block: 3rem 1rem;
  padding-inline: 1.5rem;
  position: relative;
  width: 100%;
  margin-top: 6rem;
}

.nca-footer .get-started-card {
  top: -5rem;
  display: flex;
  flex-flow: column;
  gap: 0.25rem;
  background-color: #CF3560;
  padding: 1.125rem;
  border-radius: 0.25rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  max-width: 75rem;
}

.nca-footer .get-started-card .controls {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.nca-footer .get-started-card .controls .start-free {
  background-color: transparent;
  border: none;
  padding: 0;
  height: 40px;
}

.nca-footer .footer-head {
  display: flex;
  justify-content: space-between;
  max-width: 70.875rem;
}

.nca-footer .footer-head img {
  width: 100%;
  height: auto;
  max-width: 2.6rem;
}

.nca-footer .footer-body {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  gap: 2rem;
  grid-template-areas: "links" "social" "contact" "tm";
}

.nca-footer .footer-body .footer-links {
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 0.45rem;
  grid-area: links;
}

.nca-footer .footer-body .footer-links a {
  text-decoration: none;
  color: white;
  font-size: 0.875rem;
}

.nca-footer .footer-body .footer-social {
  grid-area: social;
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.nca-footer .footer-body .footer-social a {
  display: flex;
  gap: 0.6rem;
  align-items: center;
  color: white;
  text-decoration: none;
  font-size: 0.75rem;
}

.nca-footer .footer-body .footer-social a .social-name {
  display: none;
}

.nca-footer .footer-body .footer-contact {
  grid-area: contact;
  color: white;
  font-size: 0.75rem;
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  gap: 0.45rem;
  width: 60%;
}

.nca-footer .footer-body .footer-contact .footer-link {
  text-decoration: none;
  color: white;
}

.nca-footer .footer-body .footer-trademark {
  grid-area: tm;
  color: white;
  font-size: 0.563rem;
  text-align: center;
}

.landing {
  width: 100vw;
  height: auto;
  display: grid;
  grid-template-rows: 1fr 30rem;
  overflow: hidden;
  justify-items: center;
}

.landing .top-section {
  width: 100%;
  min-height: 520px;
  max-height: 520px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding-inline: 1.5rem;
  text-align: center;
  color: white;
  background-image: linear-gradient(to bottom, #cf3560, #d33b65, #d64069, #da466e, #dd4b73);
  padding-top: 18vh;
  position: relative;
  background-size: cover;
  background-position-y: center;
}

.landing .top-section .landing-taglines {
  position: absolute;
  z-index: 15;
  top: 100px;
}

.landing .top-section .landing-taglines h3 {
  margin-bottom: 0.2rem;
  line-height: 1.8rem;
}

.landing .top-section .evolution-img {
  display: flex;
  flex-flow: column;
  position: relative;
  height: 10vh;
  width: 100%;
  position: absolute;
  bottom: 100px;
}

.landing .top-section .evolution-img img {
  width: 100%;
  height: auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.landing .top-section .evolution-img img:nth-child(1) {
  bottom: 100px;
  max-width: 16rem;
  z-index: 1;
}

.landing .top-section .evolution-img img:nth-child(2) {
  bottom: -50px;
  max-width: 20rem;
}

.landing .top-section .devices {
  width: 100%;
  height: auto;
  max-width: 18rem;
  max-height: 60vh;
  position: absolute;
  top: 22rem;
}

.landing .top-section .devices img {
  width: 100%;
  height: auto;
  position: absolute;
}

.landing .top-section .devices img:nth-child(1) {
  top: 30px;
  right: -46px;
  max-width: 22.5rem;
  z-index: 2;
}

.landing .top-section .devices img:nth-child(2) {
  top: -50px;
  left: -86px;
  max-width: 16rem;
  z-index: 3;
}

.landing .top-section .devices img:nth-child(3) {
  top: 220px;
  left: 0px;
  max-width: 22.5rem;
  z-index: 1;
}

.landing .lower-section {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  padding-block: 6rem 2.25rem;
  padding-inline: 1rem;
  width: 100%;
  max-width: 26rem;
  height: 100%;
}

.landing .lower-section p {
  letter-spacing: 2px;
  color: #CF3560;
}

.landing .lower-section .input-fields {
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
}

.landing .lower-section .input-fields .gs-desktop {
  width: auto;
  display: none;
  border: none;
  background-color: transparent;
  height: 2.4rem;
}

.landing .lower-section .input-fields .gs-desktop svg {
  width: 100%;
  height: auto;
  max-width: 2.4rem;
}

.about {
  width: 100%;
  max-width: 62.5rem;
  padding: 1rem;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.about .about-top {
  width: 100%;
  max-width: 62.5rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #D1D1D6;
  text-align: center;
}

.about .about-top p {
  margin-top: 0.5rem;
  color: #48484A;
  line-height: 18px;
}

.about .about-middle {
  width: 100%;
  max-width: 31rem;
  border-bottom: 1px solid #D1D1D6;
  padding: 2rem 1rem;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.about .about-middle .problem-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  margin-top: 1.5rem;
}

.about .about-middle .problem-list .row {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.75rem;
}

.about .about-middle .problem-list .row .thumbnail img {
  width: auto;
  height: auto;
  max-width: 3.125rem;
}

.about .about-middle .problem-list .row .description {
  font-size: 0.75rem;
  font-weight: 600;
  color: #48484A;
  align-self: center;
  text-align: left;
}

.about .about-bottom {
  width: 100%;
  max-width: 70rem;
  padding: 2rem 1rem;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.about .about-bottom .solution-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(1fr, 20rem));
}

.about .about-bottom .solution-list .row {
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 0.313rem;
  padding-block: 1rem;
}

.about .about-bottom .solution-list .row .sol-ic img {
  width: auto;
  height: auto;
  max-width: 7.5rem;
}

.about .about-bottom .solution-list .row .sol-desc {
  text-align: center;
}

.about .about-bottom .solution-list .row .sol-desc .sol-desc {
  color: #48484A;
}

.about .about-bottom .solution-list .row .sol-desc .sol-link {
  color: #48484A;
  text-decoration: underline;
}

.services-mobile {
  display: block;
}

.services-desktop {
  display: none;
}

.services-desktop .service-info-card {
  width: 100%;
  max-width: 63.75rem;
  display: flex !important;
  justify-content: space-between;
}

.services-desktop .service-info-card:nth-child(even) {
  flex-flow: row-reverse;
}

.services-desktop .service-info-card .service-text {
  width: 60%;
  line-height: 1.6rem;
}

.services {
  padding: 2rem 1.5rem;
  text-align: center;
  width: 100%;
  max-width: 50rem;
}

.services .service-info-card {
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  gap: 0.75rem;
  width: auto;
  padding-block: 2.5rem;
}

.services .service-info-card .title {
  width: 100%;
  border-bottom: 1px solid #48484A;
  padding-bottom: 0.75rem;
}

.services .service-info-card .desc {
  color: #48484A;
}

.services .service-info-card img {
  width: 100%;
  height: auto;
  max-width: 11.5rem;
}

.services .service-info-card .disabled {
  color: #8E8E93 !important;
}

.services .service-info-card .link {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  color: #48484A;
  text-decoration: none;
}

.faq-section {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 2rem 1.5rem;
  gap: 0.25rem;
  max-width: 40rem;
}

.faq-section .faq-header {
  padding-block: 1.5rem;
  text-align: center;
}

.faq-section .all-faq {
  display: flex;
  flex-flow: column;
}

.faq-section .all-faq .faq {
  padding-block: 0.75rem;
  display: grid;
  grid-template-columns: 1fr auto;
  border-bottom: 1px solid #48484A;
  text-align: left;
  align-items: flex-start;
  cursor: pointer;
}

.faq-section .all-faq .faq:last-child {
  border-bottom: 0px;
}

.faq-section .all-faq .faq .faq-arrow {
  color: #8E8E93;
  align-self: start;
}

.faq-section .all-faq .faq .faq-text .answer {
  transition: transform 0.3s ease 0s;
  transform: scaleX(0);
  display: none;
  padding-top: 0.6rem;
  font-weight: 600;
}

#products-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 80%;
  min-height: 100vh;
  padding-block: 8rem 6rem;
  text-align: center;
}

/*
#products-container .product-title {
  padding-top: 1rem;
}
*/

/*
#products-container .product-title .title {
   font-size: 2rem; 
  padding-bottom: 4rem;
}
*/

#products-container .product-title .product-features {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding-top: 3rem;
  justify-items: center;
}

#products-container .product-title .product-features .feature {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  justify-items: center;
}

#products-container .product-full {
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 2rem;
  padding-top: 5rem;
}

#products-container .product-full .product {
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 0.8rem;
  padding-top: 4rem;
}

#products-container .product-full .product .pd-title {
  width: 80%;
}

#products-container .product-full .product .pd-desc {
  width: 60%;
}

#products-container .product-full .product .pd-img {
  width: 100%;
  height: auto;
  max-width: 44rem;
  border-radius: 0.8rem;
  margin-top: 1.4rem;
}

#products-container .product-full .check-apps {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

#products-container .product-full .check-apps a {
  color: #CF3560;
  font-weight: 600;
  text-decoration: none;
}

@media screen and (max-width: 900px) {
  .desc {
    font-size: 0.75rem;
  }

  .product-features {
    grid-template-columns: 1fr !important;
    gap: 4rem;
  }

  .product-features .feature p {
    margin-top: 1.6rem;
  }

  .product {
    padding-top: 1rem !important;
  }

  .product .pd-title {
    font-size: 1rem;
    width: 80% !important;
  }

  .product .pd-desc {
    width: 96% !important;
    font-size: 0.75rem;
  }
}

.case-studies {
  /* padding-block: 3rem 1rem; */
  padding-block: 0rem 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  width: 100%;
  margin-bottom: 4rem;
}

.case-studies .case-study {
  display: grid;
  grid-template-rows: 1fr auto;
  gap: 1rem;
  text-align: center;
  border: 4px solid #E5E5EA;
  border-radius: 1.25rem;
  padding: 2rem;
  cursor: pointer;
}

.case-studies .case-study .brief .case-study__title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.6rem;
}

.case-studies .case-study .brief .case-study__desc {
  font-size: 1rem;
  font-weight: 400;
}

.case-studies .case-study:hover {
  border-color: white;
  box-shadow: 0rem 2rem 4rem rgba(17, 17, 17, 0.08);
}

.case-studies .case-study:hover .brief .case-study__title {
  color: #CF3560;
}

.bottom-text {
  text-align: center;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 1rem;
  width: 90%;
}

.case-details-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.459);
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.case-details-wrapper .case-detail {
  position: relative;
  padding: 3rem;
  width: 100%;
  margin-inline: 2rem;
  max-width: 60rem;
  height: 100%;
  max-height: 90vh;
  overflow-y: auto;
  border-radius: 1.5rem;
  border: 5px solid #E5E5EA;
  background-color: white;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.8rem;
}

.case-details-wrapper .case-detail .case-img img {
  width: 100%;
  height: auto;
}

.case-details-wrapper .case-detail .caseList h2 {
  color: #D1D1D6;
  font-weight: 500;
  font-size: 2rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid #E5E5EA;
}

.case-details-wrapper .case-detail ul {
  list-style-type: none;
}

.case-details-wrapper .case-detail ul .tick-top {
  align-items: flex-start;
}

.case-details-wrapper .case-detail ul .tick-top span {
  position: relative;
  top: 10px;
}

.case-details-wrapper .case-detail ul li {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  font-size: 0.9rem;
}

.case-details-wrapper .case-detail .tick {
  display: block;
  width: 10px;
  height: 2px;
  background-color: #E5E5EA;
  border-radius: 1rem;
}

.case-details-wrapper .case-detail .case-description {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.case-details-wrapper .case-detail .case-description .top-text h1 {
  font-size: 2.6rem;
  font-weight: 600;
}

.case-details-wrapper .case-detail .case-description .top-text p {
  font-size: 0.9rem;
}

.case-details-wrapper .case-detail .case-description a {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  color: #CF3560;
  text-decoration: none;
  font-weight: 600;
}

.closeIcon {
  color: #1C1C1E;
  position: absolute;
  top: 15px;
  right: 20px;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  #case-studies {
    width: 100%;
    padding-inline: 1rem;
  }

  .case-detail {
    max-width: auto;
    margin-inline: 0rem !important;
    font-size: 0.8rem;
    padding: 0.6rem !important;
    grid-template-columns: 1fr !important;
  }

  .case-detail .case-description .top-text h1 {
    font-size: 1.7rem !important;
  }
}

.pricings {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
  /* padding-block: 1rem 2.8rem; */
  padding-block: 3rem 2.8rem;
  /* margin-bottom: 1rem; */
  margin-top: 3rem;
  gap: 1rem;
  width: 100%;
  /* border-bottom: 1px solid #1C1C1E; */
  border-top: 1px solid #48484A;
}

.pricings .plan {
  border-radius: 1rem;
  padding: 1rem;
  border: 1px solid #1C1C1E;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  gap: 1.6rem;
}

.pricings .plan .plan-title {
  width: 100%;
  padding-bottom: 1rem;
  border-bottom: 1px solid #1C1C1E;
}

.pricings .plan .gs-btn {
  width: 100%;
}

.pricings .plan .gs-btn button a {
  text-decoration: none;
  width: 100%;
  height: 100%;
  display: block;
  color: #1C1C1E;
}

.pricings .plan .gs-btn button {
  background-color: transparent;
  border: 2px solid #1C1C1E;
  color: #1C1C1E;
}

.pricings .plan .gs-btn button:hover {
  border-color: #CF3560;
  color: #CF3560;
}

.pricings .plan .plan-features {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  gap: 0.8rem;
  font-size: 0.75rem;
  color: #2C2C2E;
  font-weight: 500;
}

.pricings .plan .plan-features .sub-features {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.6rem;
}

.pricings .plan ul {
  list-style-type: none;
}

.page-title {
  padding-bottom: 1.6rem;
}

.description {
  text-align: center;
  width: 100%;
}

.contact-section {
  width: 100%;
  min-width: 16rem;
  background-image: url("../assets/images/aboutUsRedBg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: bottom;
  display: flex;
  margin-top: 6rem;
  justify-content: flex-end;
  position: relative;
}

.contact-cover {
  height: 4rem;
}

.contact-cover p {
  font-size: 2rem;
  position: absolute;
  bottom: 8rem;
  left: 4%;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 24rem;
  gap: 1rem;
  padding: 0.6rem;
  background-color: white;
  border-radius: 0.6rem;
  transform: translate(-2rem, -2rem);
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  resize: none;
}

.contact-form button {
  padding-block: 0.8rem;
  background-color: #1C1C1E;
  max-width: 10rem;
}

@media screen and (max-width: 1300px) {
  .contact-section {
    justify-content: center;
    background-position-y: top;
    margin-bottom: 8rem;
  }

  .contact-form {
    transform: translateY(8rem);
  }

  .contact-cover p {
    top: 2.8rem;
    font-size: 1rem;
  }
}

@media screen and (min-width: 60rem) {
  .container {
    width: 80%;
  }

  .landing {
    grid-template-rows: 1fr 23rem !important;
  }

  .top-section {
    height: 78vh !important;
  }

  .top-section .evolution-img {
    bottom: 156px !important;
  }

  .top-section .evolution-img img:nth-child(1) {
    bottom: 10px !important;
    max-width: 34rem !important;
  }

  .top-section .evolution-img img:nth-child(2) {
    bottom: 0px !important;
    max-width: 32rem !important;
  }

  .routes .submenu-enabled {
    font-weight: 500 !important;
  }

  .routes .submenu-enabled span {
    font-size: 1.2rem;
  }

  .routes .main-links {
    text-decoration: none !important;
  }

  .routes .main-links .nav-links {
    font-weight: 500 !important;
  }

  .nav-white .routes .submenu-enabled {
    font-weight: 600 !important;
  }

  .nav-white .routes .submenu-enabled .submenu {
    box-shadow: 0px 0px 6px 2px rgba(58, 58, 60, 0.3490196078);
  }

  .solution-list {
    grid-template-columns: repeat(3, 20rem) !important;
  }

  .about-bottom {
    max-width: 70rem;
  }

  .service-info-card {
    text-align: left;
  }

  .services-mobile {
    display: none !important;
  }

  .services-desktop {
    display: block !important;
  }

  .devices {
    top: 20rem !important;
    max-width: 48rem !important;
  }

  .devices img:nth-child(1) {
    top: 0rem !important;
    left: -4.375rem;
    max-width: 27rem !important;
  }

  .devices img:nth-child(2) {
    transform: translateX(-50%);
    top: -1.5rem !important;
    max-width: 18rem !important;
    left: 50% !important;
  }

  .devices img:nth-child(3) {
    top: 0rem !important;
    left: 21.875rem !important;
    max-width: 30rem !important;
  }

  .landing-taglines {
    top: 184px !important;
  }

  .nca-footer {
    padding-inline: 5.5rem;
    padding-bottom: 0.4rem;
    padding-top: 2.8rem;
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  .nca-footer .footer-head {
    width: 100%;
  }

  .nca-footer .footer-body {
    grid-template-columns: repeat(3, 1fr) !important;
    grid-template-rows: auto auto !important;
    gap: 0 !important;
    justify-items: flex-start;
    text-align: left;
    transform: translateY(-24%);
    grid-template-areas: "contact links social" "contact tm .";
  }

  .nca-footer .footer-body .footer-links {
    width: 100%;
    align-items: flex-start !important;
  }

  .nca-footer .footer-body .footer-social {
    flex-flow: column !important;
    gap: 0.2rem !important;
    justify-content: flex-start;
  }

  .nca-footer .footer-body .footer-social a .social-name {
    display: inline;
  }

  .nca-footer .footer-body .footer-contact {
    text-align: left;
    align-items: flex-start;
    gap: 2.2rem;
  }

  .nca-footer .footer-body .footer-trademark {
    width: 100%;
    text-align: left;
    display: flex;
    align-items: flex-end;
  }

  .get-started-card {
    padding: 2rem 4rem !important;
    flex-flow: row !important;
    align-items: center !important;
    justify-content: space-between !important;
  }

  .navigation {
    padding-inline: 3rem !important;
  }

  .navigation .nav-ham {
    display: none;
  }

  .navigation-wrapper {
    background-color: transparent;
    padding: 0 !important;
    transform: translateX(0%) !important;
    position: relative;
    height: auto;
    flex-flow: row;
    align-items: center !important;
    width: 100% !important;
    max-width: unset !important;
  }

  .navigation-wrapper::before {
    content: "";
    width: 10%;
  }

  .navigation-wrapper .nav-bottom {
    max-width: 8.75rem;
  }

  .navigation-wrapper .nav-bottom button {
    background-color: transparent;
    border: 2px solid white;
  }

  .navigation-wrapper .nav-bottom .help_link {
    display: none;
  }

  .navigation-wrapper .nav-bottom button {
    background-color: transparent;
    border: 2px solid #CF3560;
  }

  .navigation-wrapper .nav-bottom button a {
    color: #CF3560;
  }

  .navigation-wrapper .nav-top {
    width: 48%;
  }

  .navigation-wrapper .nav-top .mob-nav-logo {
    display: none;
  }

  .navigation-wrapper .nav-top .routes {
    padding: 0 !important;
    flex-flow: row !important;
    justify-content: space-between;
  }

  .navigation-wrapper .nav-top .routes .main-links {
    cursor: pointer;
    width: auto !important;
  }

  .navigation-wrapper .nav-top .routes .nav-links {
    color: white;
  }

  .lower-section {
    padding-top: 18rem !important;
  }

  .lower-section .input-fields {
    flex-flow: row !important;
    align-items: center;
  }

  .submenu {
    position: absolute;
    top: 40px;
    background-color: white;
    min-width: 13.5rem;
    padding: 0.5rem;
    padding-inline: 0.5rem !important;
    border-radius: 0.5rem;
  }

  .submenu li {
    border: none !important;
    width: 100% !important;
    color: #1C1C1E;
    padding-inline: 0.4rem !important;
  }

  .gs-desktop {
    display: block !important;
  }

  .gs-mob {
    display: none;
  }

  .bottom-text {
    width: 60%;
  }

  .contact-section {
    width: 80%;
  }

  .contact-form {
    padding: 2rem;
  }
}

@media screen and (min-width: 1920px) {
  html {
    font-size: 1.25rem;
  }

  .landing-taglines {
    bottom: 13.75rem !important;
  }

  .devices {
    top: 18rem !important;
  }

  .evolution-img #evo-bg {
    bottom: -36px !important;
  }

  .landing {
    grid-template-rows: 1fr 28rem !important;
  }
}

@media screen and (min-width: 820px) and (max-width: 960px) {
  .evolution-img img:nth-child(1) {
    bottom: 42px !important;
    max-width: 30rem !important;
  }

  .evolution-img img:nth-child(2) {
    bottom: 20px !important;
    max-width: 24rem !important;
  }

  .devices img:nth-child(1) {
    top: 0rem !important;
    left: -10.375rem;
    max-width: 32rem !important;
  }

  .devices img:nth-child(2) {
    transform: translateX(-50%);
    top: -1.5rem !important;
    max-width: 18rem !important;
    left: 50% !important;
  }

  .devices img:nth-child(3) {
    top: 0rem !important;
    left: unset !important;
    right: -10rem !important;
    max-width: 38rem !important;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  overflow-x: hidden;
  display: flex;
  flex-flow: column;
  align-items: center;
}

html {
  scroll-behavior: smooth;
}

#root {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
}

.container {
  width: 90%;
  padding-block: 8rem 4rem;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.open-nav {
  transform: translateX(0%) !important;
}

.open-submenu {
  transform: scaleY(1) !important;
}

.active-tab {
  border-bottom: 1px solid #1C1C1E;
}

.open-faq {
  display: block !important;
  transform: scaleY(1) !important;
}

#nav-white svg>path {
  stroke: white;
}

.nav-white .nav-bottom>button {
  border-color: white;
}

.nav-white .nav-bottom>button a {
  color: white;
}

.nav-black .nav-links {
  color: #1C1C1E !important;
}

.nav-black .nav-bottom>button {
  border-color: #CF3560 !important;
}

.page-main-title {
  padding-bottom: 3rem;
}

.custom-container {
  width: 90%;
}

.pricing-border {
  border-bottom: 3px solid #E5E5EA;
  padding-bottom: 2.5rem;
}

.menu-option {
  padding: 16px;
  cursor: pointer;
  border-bottom: 1px solid #D1D1D6;
  ;
}

.menu-option.active {
  font-weight: bold;
}

tr:last-child td {
  border-bottom: none;
}

article {
  grid-area: content;
  padding: 20px;
}

aside {
  grid-area: sidebar;
}

.wrapper {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 3fr;
  grid-template-areas:
    "sidebar content";
}

@media (max-width: 500px) {
  .wrapper {
    grid-template-columns: 4fr;
    grid-template-areas:
      "sidebar"
      "content";
  }
}

.case-studies {
  width: 90%;
}

.pricing {
  padding-block: 1.5rem 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
}

@media screen and (max-width: 600px) {
  #pricing {
    width: 100%;
    padding-inline: 1rem;
  }
}

.pricing-border-darker {
  border-bottom: 1px solid #48484A;
  padding-bottom: 1.25rem;
}

.pricing-table-container table {
  margin: auto;
  border: 1px solid #E5E5EA;
  border-radius: 10px;
}

.pricing table {
  width: 100%;
  padding: 1rem;
  table-layout: fixed;
}

.pricing td {
  text-align: center;
  padding: 0.4rem;
  border-bottom: 1px solid #E5E5EA;
}

.social-media-icons {
  display: inline-block;
}

.vertical-table th {
  border-bottom: 1px solid #E5E5EA;
}

.pricing tr:last-child th {
  border-bottom: none;
}

.font-light-bold {
  font-weight: 600;
}

#pricing-link {
  padding-left: 1rem;
  color: black;
  cursor: pointer;
}

.pricing select {
  padding: 0.5rem 0.75rem;
  border: 1px solid #E5E5EA;
  border-radius: 5px 5px 5px 5px;
}

.responsive-titles {
  display: flex;
  align-items: center;
}

.titles-left-align h1,
a {
  margin-right: 1rem;
}

.right-align {
  margin-left: auto;
}

.titles-left-align {
  display: flex;
  align-items: center;
}

.select-wrapper {
  display: flex;
  align-items: center;
}

.select-wrapper label {
  font-size: 0.95rem;
  margin-right: 0.5rem;
}

@media (max-width: 600px) {
  .responsive-titles {
    display: inline-block;
  }
}

.popup-card {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 80%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  max-height: 90vh;
  overflow-y: auto;
  border-radius: 1.5rem;
  border: 5px solid #E5E5EA;
  background-color: white;
  padding: 2.5rem;
}

.pad-top-3 {
  padding-top: 3rem;
}

.popup-card-body {
  margin-top: 20px;
}

.popup-width {
  width: 100%;
}

.input-secondary {
  padding: 0.5rem 0.75rem;
  border-radius: 0.313rem;
  border: 1px solid #D1D1D6;
  accent-color: #636366;
}

.input-secondary:focus {
  outline: none;
  border-color: #1C1C1E;
}

.breakdown-sliders {
  padding-top: 1.5rem;
  padding-bottom: 1rem;
}

.breakdown-slider {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
}

.input-slider {
  width: 90%;
}

.breakdown-subtitle {
  border-bottom: 1px solid #E5E5EA;
  padding-bottom: 1rem;
}

.back-icon {
  cursor: pointer;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.social-tab {
  display: flex;
  align-items: center;
}

@keyframes slideOutLeft {
  0% {
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

#div1 {
  width: 90%;
}

#div1>div {
  animation: slideOutLeft 0.5s;
}

#div2 {
  width: 90%;
}

.hidden {
  display: none;
}

.wid-90 {
  width: 90%;
}

.breakdown {
  border: 1px solid #E5E5EA;
  border-radius: 10px;
  padding: 1rem;
  margin-top: 1rem;
}

.material-symbols-outlined {
  padding-right: 0.5rem;
}

.icon-title {
  display: flex;
}

.policy-main-title {
  padding-bottom: 2rem;
}

.policy-description {
  padding-bottom: 1.5rem;
}